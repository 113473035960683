<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('invoiceFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm" @click="openModalEvent('create','invoiceForm', null, 'Yeni Fatura Ekle')">Yeni Ekle</CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="productionInvoices"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('detail', 'invoiceDetail', lastItem, lastItem.invoiceNo+' nolu fatura detayı')"
            v-on:refresh="filterProductionInvoices"
          >
            <template #fileName="{item}">
              <td @click="openPdf(item.fileName)">
                <CIcon v-if="item.fileName!== null" id="pdf"
                       name="cil-file"/>
                <CIcon v-else id="pdf"
                       name="cil-x"/>
              </td>
            </template>
            <template #invoiceDate="{item}">
              <td> {{ item.invoiceDate && dateFormat(item.invoiceDate) }} </td>
            </template>
            <template #dispatchDate="{item}">
              <td> {{item.dispatchDate && dateFormat(item.dispatchDate) }} </td>
            </template>
            <template #delete="{item}">
              <td>
                <span style="color: red; font-weight: bold; cursor: pointer;"
                      @click="openModalEvent('destroy', 'confirm', item, 'Sil', item.invoiceNo+' nolu faturayı silmek üzeresiniz. Bu işlemi yapmak istediğinizden emin misiniz?')">Sil</span>
              </td>
            </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('detail', 'invoiceDetail', item, item.invoiceNo+' nolu fatura detayı')">Fatura Detay</CDropdownItem>
                  <CDropdownItem @click="openModalEvent('update', 'invoiceForm', item, item.invoiceNo+' nolu faturayı düzenle')">Düzenle</CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="removeItem"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      ref="confirm"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="submitForm"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"

    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterProductionInvoices"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>
import moment from "moment";
import FilterSidebar from '../components/filterSidebar'
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'ProductionInvoice',
  components:{FilterSidebar, FormModal, ConfirmModal },
  computed: {
    productionInvoices(){
      return this.$store.getters.productionInvoices
    },
    loading(){
      return this.$store.getters.productionLoading
    },
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'production',
      form: 'invoiceForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'packingFilter',
      filterParams: {},
      lastItem: {},

      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'ID', _style: 'font-size:12px'},
        {key: 'productionOrderId', label: 'Sipariş Id', _style: 'font-size:12px'},
        {key: 'productionOrder', label: 'Sipariş No', _style: 'font-size:12px'},
        {key: 'productionCompany', label: 'Üretici', _style: 'font-size:12px'},
        {key: 'invoiceNo', label: 'Fatura No', _style: 'font-size:12px'},
        {key: 'invoiceDate', label: 'Fatura Tarihi', _style: 'font-size:12px'},
        {key: 'dispatchNo', label: 'İrsaliye No', _style: 'font-size:12px'},
        {key: 'dispatchDate', label: 'İrsaliye Tarihi', _style: 'font-size:12px'},
        {key: 'totalNumber', label: 'Toplam Adet', _style: 'font-size:12px'},
        {key: 'totalAmount', label: 'Toplam Tutar', _style: 'font-size:12px'},
        {key: 'vatAmount', label: 'KDV Tutarı', _style: 'font-size:12px'},
        {key: 'fileName', label: 'Fatura PDF', _style: 'font-size:12px'},
        {key: 'delete', label: 'Sil', _style: 'font-size:12px'}
      ],
      filters: [{
        type: 'numeric',
        dataIndex: 'productionOrderId',
        field: 'productionOrderId',
        comparison: 'eq',
      }, {
        type: 'string',
        dataIndex: 'productionOrder',
        field: 'productionOrder',
      }, {
        type: 'string',
        dataIndex: 'productionCompany',
        field: 'productionCompany',
      }, {
        type: 'string',
        dataIndex: 'invoiceNo',
        field: 'invoiceNo'
      }, {
        type: 'string',
        dataIndex: 'dispatchNo',
        field: 'dispatchNo'
      },
      {
        type: 'date',
        dataIndex: 'startTime',
        field: 'invoiceDate',
        comparison: 'gt'
      },
      {
        type: 'date',
        dataIndex: 'endTime',
        field: 'invoiceDate',
        comparison: 'lt',
      
      }],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    modalProps: function(val){
      if (val && val.invoiceDate)
        this.modalProps.invoiceDate = moment(val.invoiceDate).format("YYYY-MM-DD")
      if (val && val.dispatchDate)
        this.modalProps.dispatchDate = moment(val.dispatchDate).format("YYYY-MM-DD")
      
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item;
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    removeItem: async function(item){
        await this.$store.dispatch('productionInvoice_remove', {"invoiceId": item.id})
        if(this.$store.getters.productionStatus.success){
            this.openModalEvent()
            this.filterProductionInvoices(this.filterParams)
        }
    },
    async openModalEvent(actionType, form, data, title, desc){
      if(form == 'invoiceForm'){
        if(actionType == 'update'){
          let production = this.$store.getters.productionCompanies.find(c => c.name == data.productionCompany)
          data.productionCompany = production ? production.id : 0
          await this.$store.dispatch('productionCompanyOrder_list', {productionCompanyId: data.productionCompany})
          data.orderId = data.productionOrderId
        }
      }else if(form == 'invoiceDetail'){
        await this.$store.dispatch('productionOrderPacking_list', { productionOrderId: data.productionOrderId});
        await this.$store.dispatch('productionInvoiceDetail_list', { productionInvoiceId: data.id});
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    async submitForm(data, action){
      if(this.form == 'invoiceDetail'){
        let params = {
          "invoiceId": data.id,
          "productionPackingId": data.productionPackingId,
          "invoiceAmount": parseInt(data.invoiceAmount)
        }
        await this.$store.dispatch('productionInvoiceDetail_action', params)
        if(this.$store.getters.productionStatus.success){
          await this.$store.dispatch('productionInvoiceDetail_list', { productionInvoiceId: data.id});
        }
      }else{
        const formData = new FormData();
        formData.append("id", action == 'create' ? null : data.id);
        formData.append("orderId", data.orderId);
        formData.append("invoiceNo", data.invoiceNo);
        formData.append("invoiceDate", moment(data.invoiceDate).format('DD/MM/YYYY'));
        formData.append("dispatchNo", data.dispatchNo);
        formData.append("dispatchDate", moment(data.dispatchDate).format('DD/MM/YYYY'));
        formData.append("totalAmount", data.totalAmount);
        formData.append("vatAmount", data.vatAmount);
        formData.append("file", data.file);
        await this.$store.dispatch('productionInvoice_action', formData);
        if(this.$store.getters.productionStatus.success){
          this.filterProductionInvoices(this.filterParams)
          this.openModalEvent()
        }
      }
    },
    openPdf(url) {
      url !== null ? window.open(url, "_blank") : '';
    },
    async productionInvoice() {
      await this.$store.dispatch('productionInvoice_list')
    },
    
    async productionCompanies() {
      await this.$store.dispatch('productionCompanies_list')

    },
    async filterProductionInvoices(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('productionInvoice_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.productionInvoice();
    this.productionCompanies();
  }
}
</script>
